<template>
  <div class="sort-details">
    <mall-head />
    <div class="sort-head w1200">
      <span style="color: red">{{ sortName }} </span>
      <span> 商品筛选</span>
    </div>

    <div class="filter w1200">
      <ul class="filter-list">
        <li
          v-for="(item, index) in filter"
          :key="index"
          :class="{ active: index == isActive }"
          @click="btnClick(index)"
        >
          {{ item }}
        </li>
      </ul>
      <div class="filter-page">
        <span>1/1</span>
        <i class="el-icon-arrow-left"></i>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="check-box w1200">
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="自营"></el-checkbox>
        <el-checkbox label="仅显示有货"></el-checkbox>
        <el-checkbox label="巨划算"></el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="goods-list w1200">
      <el-row :gutter="20">
        <el-col :span="6" v-for="(item, index) in goodsData" :key="index">
          <div class="goods-item" @click="openGoods(item.name)">
            <el-image :src="item.coverImg" />
            <div class="details">
              {{ item.details }}
            </div>
            <div class="price">
              <span>¥ </span>
              <span class="price_num">{{ item.price }}</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <MallFooter />
  </div>
</template>

<script>
import MallHead from "@/views/MallHead.vue";
import MallFooter from "@/views/MallFooter.vue";
import { filter, sortGoods } from "../utils/index";
export default {
  name: "SortDetails",
  components: { MallHead, MallFooter },
  data() {
    return {
      sortName: this.$route.query.name,
      filter,
      isActive: 0,
      checkList: ["仅显示有货"],
      goodsData: sortGoods[this.$route.query.id],
    };
  },
  methods: {
    btnClick(index) {
      this.isActive = index;
    },
    openGoods(name) {
      let routeUrl = this.$router.resolve({
        path: "/goodsDetails" + name,
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.sort-head {
  height: 36px;
  line-height: 36px;
  padding-left: 10px;
  background-color: #f1f1f1;
  font-weight: 700;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-top: 1px;
}
.filter {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  background-color: #f3f3f3;
  padding: 6px 10px;
}
.filter-list {
  display: flex;
  width: 322px;
  border: 1px solid #ccc;
  li {
    display: block;
    padding: 5px 20px;
    cursor: pointer;
    font-size: 12px;
    &:hover {
      color: red;
      border: 1px solid red;
      margin: -1px;
    }
  }
}
.active {
  color: #fff;
  background-color: red;
  font-weight: 700;
  &:hover {
    color: #fff !important;
    border: none !important;
    margin: 0 !important;
  }
}
.filter-page {
  span {
    margin-right: 10px;
  }
  i {
    border: 1px solid #ccc;
    padding: 6px 10px;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
  .el-icon-arrow-left {
    border-right: 0;
  }
}
.check-box {
  border: 1px solid #f3f3f3;
  border-top: 0;
  padding: 6px 30px;
}
.goods-list {
  .goods-item {
    height: 370px;
    background-color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
    }
    .el-image {
      margin: 40px 40px 30px;
    }
    .details {
      margin: 0 20px;
      color: #666;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .price {
      color: red;
      margin: 10px 20px 0;
    }
    .price_num {
      font-weight: 700;
      font-size: 20px;
    }
  }
}
</style>
